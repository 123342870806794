<template>
  <div>
    <v-progress-linear class="mt-2" indeterminate v-if="loading" color="primary darken-2"></v-progress-linear>
    <checklist-list :module="moduleId" :filterObj="filterObj" :payload="payload" :model="modelObj" :key="reInit"
      ref="checklistItems" :accesscontrol="accesscontrol"></checklist-list>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" persistent fullscreen>
      <v-card tile height="100%">
        <v-card-title class="px-4 py-3" :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }; height: 52px`">
          <v-btn small icon @click="closeDialogHandler"><v-icon :color="systemDetails.textcolor">mdi-arrow-left</v-icon></v-btn>
          <span class="body-1 font-weight-bold ml-4" :style="`color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || '#fff') }`"> {{ $t('updateChecklist') }} </span>
          <v-spacer></v-spacer>
          <v-btn x-small icon @click="saveCheckList" dark>
            <v-icon :color="systemDetails.textcolor">mdi-check-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: calc(100% - 52px); overflow-y: auto" class="pa-3">
          <form-render :key="reInit" :formId="formId" :module="moduleId" propId="record_id" :checklistId="checklistId" :formName="formName" ref="checklistFormRender"></form-render>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['accesscontrol'],
  data () {
    return {
      modelObj: {},
      moduleId: this.$route.params.id,
      filterObj: 'CHEMICAL_CHECKLIST_INDEX',
      reInit: 0,
      payload: {},
      dialog: false,
      formId: '',
      formName: '',
      checklistId: '',
      loading: false
    }
  },
  components: {
    'checklist-list': () => import('@/components/ChecklistNew/List'),
    'form-render': () => import('@/components/ChecklistNew/Actions')
  },
  computed: {
    ...mapGetters(['systemDetails'])
  },
  mounted () {
    this.$eventBus.$on('chemicalModel', (data) => {
      this.modelObj = data
      this.reInit++
    })
    this.$root.$on('checklistDialog', (data) => {
      this.formId = data.form_id
      this.checklistId = null
      this.formName = data.form_name
      this.reInit++
      this.dialog = true
    })
    this.$eventBus.$on('updatechecklistDialog', (data) => {
      this.dialog = true
      this.checklistId = data.checklist_id || null
      this.formId = data.form_id
      this.formName = data.form_name
      // this.$nextTick(() => {
      //   if (this.$refs.checklistFormRender) this.$refs.checklistFormRender.reInit++
      // })
      this.reInit++
    })
    this.$eventBus.$on('setChecklistId', (checklistId) => {
      this.checklistId = checklistId
    })
    this.$eventBus.$on('showLoading', () => {
      this.loading = true
    })
    this.$eventBus.$on('hideLoading', () => {
      this.loading = false
    })
    this.$root.$on('closeDialog', () => {
      this.closeDialogHandler()
    })
  },
  methods: {
    closeDialogHandler () {
      this.$root.$emit('clearChecklist')
      this.$refs.checklistItems && this.$refs.checklistItems.clearChecklist()
      this.dialog = false
    },
    saveCheckList () {
      this.$root.$emit('saveCheckListHandler')
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('chemicalModel')
    this.$eventBus.$off('checklistDialog')
    this.$eventBus.$off('updatechecklistDialog')
    this.$eventBus.$off('closeChecklistDialog')
    this.$eventBus.$off('setChecklistId')
    this.$eventBus.$off('hideLoading')
    this.$eventBus.$off('showLoading')
  }
}
</script>
